'use client'
import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	base: 'w-full bg-gray-100 text-gray-700 lg:flex max-sm:h-screen max-lg:h-[84vh] max-lg:overflow-auto relative h-full',
})

interface AuthProps extends VariantProps<typeof styles> {
	className?: string
	children?: React.ReactNode
}

export const Auth = ({ className, children, ...rest }: AuthProps) => (
	<section {...rest} className={styles({ class: className })}>
		{children}
	</section>
)
